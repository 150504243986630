import 'whatwg-fetch';
import { cookies } from '../cookies/Cookies.js';
import { config } from '../config/config.js';
const serverUrl = config.localMode ? config.local.backendPrivate : config.deployed.backendPrivate;

class AjaxCalls {
	
	getRequest(url) {
		var init = {
		  method: "GET"
		}; 
		var request = new Request(url, init);
		return request;
	}
	
	postRequest(url, jsondata) {		
		var init = {
			method: "POST",
			body: JSON.stringify(jsondata),
			headers: { "Content-Type": "application/json" }
		}; 
		var request = new Request(url, init);
		return request;
	}
	
	getText(url) {
		return fetch(url, {credentials: "include"})
		.then(function(response) {
			return response.text();
		})
		.catch(function(error) {
			console.log(error);
		});
	}

	getJsonNoCredentials(url) {
		return fetch(url)
		.then(function(response) {
			
			return response.json();
		})
		.catch(function(error) {
			console.log(error);
		});
	}
	
	getJson(url) {		
		return fetch(url, {credentials: "include"})
		.then(function(response) {			
			return response.json();
		})
		.catch(function(error) {
			console.log(error);
		});
	}

    login(username, password) {
		var request = this.postRequest(serverUrl + "auth/signin", {username: username, password: password});
		return this.getJson(request);
	}

	isAuthenticated() {
        console.log("???");
		var request = this.getRequest(serverUrl + "isauth");
		return this.getJson(request);
	}
	
	logout() {
		var request = this.postRequest(serverUrl + "unauth");
		return this.getJson(request).then((result) => {
			cookies.removeSessionCookie();			
			return result;
		});
	}

	getAccounts() {
		var request = this.getRequest(serverUrl + "accounts");
		return this.getJson(request);
	}

    createAccount(email) {
        var request = this.postRequest(serverUrl + "accounts", {email: email});
        return this.getJson(request);
    }

}

var Ajax = new AjaxCalls();

export { Ajax };