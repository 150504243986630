import React, { Component } from 'react';
import { Ajax } from '../ajax/Ajax';
import { DashboardMenu } from './DashboardMenu.js';

class ViewAccounts extends Component {    

    constructor(props) {
		super(props);
		this.state = {accounts: [], parsedAccounts: [], sorted: false, loggedIn: false, validating: true};
        this.parseAccounts = this.parseAccounts.bind(this);
        this.sortAccounts = this.sortAccounts.bind(this);
	}

	componentDidMount() {
        var obj = this;        
		Ajax.isAuthenticated().then((res) => {
            console.log(res);
            if (res.loggedIn) {
                Ajax.getAccounts().then(accounts => {   
                                    
                    obj.setState({accounts: accounts, loggedIn: true, validating: false}, obj.parseAccounts);
                });
            } else {
                Ajax.logout().then(() => {
                    obj.setState({validating: false});
                });			
            }
        });		
    }	

    sortAccounts(field) {
		var obj = this;
		return function(event) {
			var list = obj.state.parsedAccounts;
			var keysSorted = Object.keys(list).sort(
				function(a, b) {					
					return (list[a][field].toLowerCase() < list[b][field].toLowerCase() ? -1 : 1);						
				}
			);
			obj.setState({sorted: keysSorted});
		}
	}

    parseAccounts() {
        var accounts = this.state.accounts;
        var parsedAccounts = [];
        for (var i = 0; i < accounts.length; i++) {
            var account = accounts[i];
            var dateCreated = new Date(parseInt(account._id.substring(0, 8), 16) * 1000).toISOString().substring(0,10);
            var email = account.email;
            var acceptedInvite = account.password ? "Yes" : "No";
            var name = account.name;
            var plan = account.plan;
            var parsedAccount = {id: i, dateCreated: dateCreated, email: email, acceptedInvite: acceptedInvite, name: name, plan: plan};
            parsedAccounts.push(parsedAccount);
        }
        console.log(parsedAccounts);
        var keysSorted = Object.keys(parsedAccounts).sort(
            function(a, b) {
                return (parsedAccounts[a]["dateCreated"] < parsedAccounts[b]["dateCreated"] ? -1 : 1);
            }
        ); 
        this.setState({parsedAccounts: parsedAccounts, sorted: keysSorted});
    }

	render() {


        if (this.state.validating) {
            return (<div></div>);
        }
		if (this.state.loggedIn) {
            var accounts = this.state.parsedAccounts;
            var sorted = this.state.sorted;
            return (
		<div className="Dashboard">
			<DashboardMenu />
            <div className="DashboardContent">
                <div className="Title">View Accounts</div>
                <div className="Accounts">
				<div className="AccountsHeaders">					
					<div className="AccountHeader DateCreated" onClick={this.sortAccounts("dateCreated")}>Date Created</div>
					<div className="AccountHeader Email" onClick={this.sortAccounts("email")}>E-Mail</div>
					<div className="AccountHeader InviteAccepted" onClick={this.sortAccounts("acceptedInvite")}>Invite Accepted</div>
					<div className="AccountHeader Name" onClick={this.sortAccounts("name")}>Name</div>
					<div className="AccountHeader Plan" onClick={this.sortAccounts("plan")}>Plan</div>
				</div>				
				
				{accounts && sorted ? 
				
				sorted.map(function(key, idx) {
					var account = accounts[key];
					return (
					<div id={key} key={idx} className="Account">
						<div className="AccountField DateCreated single-line">{account.dateCreated}</div>
						<div className="AccountField Email single-line">{account.email}</div>
						<div className="AccountField InviteAccepted single-line">{account.acceptedInvite}</div>
						<div className="AccountField Name single-line">{account.name}</div>
						<div className="AccountField Plan single-line">{account.plan}</div>
					</div>
					)
				}): null
				}
				
			</div>
            </div>
		</div>
		);
        } else {
            window.location.href = "/";
        }

	}
}

export { ViewAccounts };