import cookie from 'react-cookies';
import { config } from '../config/config.js';
import { isUndefined } from 'util';

class Cookies {
	
	addDays(date, amount) {
		var t = date.getTime();
		t += (1000 * 60 * 60 * 24) * amount;
		date.setTime(t);
	}

	isAuthenticated() {
		var session = cookie.load(config.cookies.sessionCookieName);
		return (! isUndefined(session));
	}

	removeSessionCookie() {
		cookie.remove(config.cookies.sessionCookieName);
		cookie.remove(config.cookies.nameCookieName);
	}

}

var cookies = new Cookies();

export { cookies };