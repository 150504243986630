import {  BrowserRouter as Router, Route } from 'react-router-dom';
import { Login } from './components/auth/Login.js';
import { Dashboard } from './components/Dashboard.js';
import { CreateAccount } from './components/CreateAccount.js';
import './App.css';
import { ViewAccounts } from './components/ViewAccounts.js';

function App() {
  return (
    <Router>
      <div className="Wrapper">
        <Route exact path='/' component={Login}/>
        <Route exact path='/dashboard' component={Dashboard}/>
        <Route exact path='/createaccount' component={CreateAccount}/>
        <Route exact path='/viewaccounts' component={ViewAccounts}/>
      </div>
    </Router>
  );
}

export default App;
