import React, { Component } from 'react';
import { Ajax } from '../ajax/Ajax';
import { DashboardMenu } from './DashboardMenu.js';

class CreateAccount extends Component {

    constructor(props) {
		super(props);
		this.state = {email: "", loggedIn: false, validating: true, sent: false};
        this.updateEmail = this.updateEmail.bind(this);
        this.send = this.send.bind(this);
	}

	componentDidMount() {
        var obj = this;        
		Ajax.isAuthenticated().then((res) => {
            console.log(res);
            if (res.loggedIn) {
                obj.setState({loggedIn: true, validating: false});
            } else {
                Ajax.logout().then(() => {
                    obj.setState({validating: false});
                });			
            }
        });		
    }	


    updateEmail(event) {
        this.setState({email: event.target.value});
    }

    send() {
        var obj = this;
        Ajax.createAccount(this.state.email).then((output) => {
            obj.setState({sent: true});
        })
    }

	render() {
        if (this.state.validating) {
            return (<div></div>);
        }
		if (this.state.loggedIn) {
            return (
		<div className="Dashboard">
			<DashboardMenu />
            <div className="DashboardContent">
                <div className="Title">Create Account</div>
                <div className="Form CreateAccountForm">
                    <div className="FormField">
                        <div className="FormFieldLabel">Username</div>
                        <input type="text" id="email" placeholder="Enter E-mail" onChange={this.updateEmail} />
                    </div>
                    {this.state.sent ? (<div className="FormField">Sent!</div>) : (<div className="Button" onClick={this.send}>Create</div>)}
                </div>
            </div>
		</div>
		);
        } else {
            window.location.href = "/";
        }

	}
}

export { CreateAccount };