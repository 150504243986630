import React, { Component } from 'react';

class DashboardMenu extends Component {
	


    
	render() {
		return (
			<div className="Menu">
                <div className="MenuTitle">Early Grooves Admin</div>
                <div className="MenuItem" onClick={() => {window.location.href = "/createaccount"}}>
                    Create Account
                </div>
                <div className="MenuItem" onClick={() => {window.location.href = "/viewaccounts"}}>
                    View Accounts
                </div>
            </div>
		);
	}
}

export { DashboardMenu };