import React, { Component } from 'react';
import { Ajax } from '../../ajax/Ajax';
import './Login.css';
import '../common/Overlay.css';
import { cookies } from '../../cookies/Cookies.js';

class Login extends Component {
	
	constructor(props) {
		super(props);
		this.login = this.login.bind(this);
		this.pressLogin = this.pressLogin.bind(this);
		this.handleUpdate = this.handleUpdate.bind(this);	
		this.state = {values: {}, error: false};		
	}
	
	handleUpdate(event) {		
		var values = this.state.values;
		values[event.target.id] = event.target.value;
		this.setState({values: values});
	}

	pressLogin(event) {
		if (event.key === "Enter") {
			this.login();
		}
	}
	
	login(e) {
		var obj = this; 
		Ajax.logout().then(() => {
			cookies.removeSessionCookie();
			Ajax.login(this.state.values.username, this.state.values.password).then(function (res) {
				if (res === undefined) {
					obj.setState({loginMessage: "Error logging in."})
				}
				else if (res.error) {
					console.log(res.error);
					console.log(res);
					obj.setState({loginMessage: res.error});
				} else {
					console.log(res);
					window.location.href = "/dashboard";
				}
			});
		});
		e.preventDefault();
	}

	//<div className="LoginRegisterLink" onClick={this.register}>No account yet? Register here!</div>
	
	render() {
		return (
		<div className="Window Login">
			<div className="WindowTopBar"></div>
			<div className="LoginRightTitle">Early Grooves Admin</div>
			<div className="Form">
				<form method="POST" onSubmit={this.login} action="signin">
					<div className="FormField">
						<div className="FormFieldLabel">Username</div>
						<input type="text" id="username" placeholder="Enter Username" onChange={this.handleUpdate} autoComplete="username" />
					</div>
					<div className="FormField">
						<div className="FormFieldLabel">Password</div>
						<input type="password" id="password" placeholder="Enter password here" onChange={this.handleUpdate} autoComplete="password" /><br/><br/>
					</div>
					<div className="Button" onClick={this.login}>Sign in</div>
					<input type="submit" value="submit" className="HiddenSubmit" />
				</form>
			</div>
		</div>
		);
	}
}

export { Login };