const localMode = false;
const livePort = 3213;
const config = {
    localMode: localMode,    
    livePort: livePort,    

    local: {
        frontend: "http://localhost:3000/",
        backend: "http://localhost:" + livePort + "/",
        backendPrivate: "http://localhost:" + livePort + "/private/",
        backendPublic: "http://localhost:" + livePort + "/public/",
        backendFiles: "http://localhost:" + livePort + "/files/",
    },

    deployed: {
        frontend: "https://admin.earlygrooves.com/",
        backend: "https://earlygrooves.com:" + livePort,
        backendPrivate: "https://earlygrooves.com:" + livePort + "/private/",
        backendPublic: "https://earlygrooves.com:" + livePort + "/public/",
        backendFiles: "https://earlygrooves.com:" + livePort + "/files/",
    },

    cookies: {
        sessionCookieName: "connect.sid",
    },    
};

export { config };