import React, { Component } from 'react';
import { Ajax } from '../ajax/Ajax';
import { DashboardMenu } from './DashboardMenu.js';

class Dashboard extends Component {
    
    constructor(props) {
        super(props);
        this.state = {loggedIn: false, validating: true}
    }

    

	componentDidMount() {
        var obj = this;        
		Ajax.isAuthenticated().then((res) => {
            console.log(res);
            if (res.loggedIn) {
                obj.setState({loggedIn: true, validating: false});
            } else {
                Ajax.logout().then(() => {
                    obj.setState({validating: false});
                });			
            }
        });		
    }		

	render() {        
        if (this.state.validating) {
            return (<div></div>)
        }
        if (this.state.loggedIn) {
            return (
                <div className="Dashboard">
                    <DashboardMenu />
                </div>
                );
        } else {
            window.location.href = "/";
        }
		
	}
}

export { Dashboard };